$(document.body).on('touchmove', animateGlobal);
$(window).on('scroll', animateGlobal);
$(document).on('ontouchmove', animateGlobal);


function animateGlobal() {

  function isScrolledIntoView(elem){
      var $elem = $(elem);
      var $window = $(window);
      var $documentG = $(document.body);
      var docViewTop = $documentG.scrollTop();
      var docViewBottom = docViewTop + $documentG.height();
      var elemTop = $elem.offset().top;
      var elemBottom = elemTop + $elem.height();
      var isVisible = (elemBottom <= docViewBottom) && (elemTop >= docViewTop);
      return isVisible;
  }

  if($("#home-servicios").length > 0) {
    if(isScrolledIntoView($(".sec-home-servicios"))) {
      $("#home-servicios .item").addClass("active");
    }
  }

  if($("#home-work").length > 0) {
    if(isScrolledIntoView($("#home-work"))) {
      $("#home-work .item").addClass("active");
    }
  }

  if($(".sec-nosotros-servicios").length > 0) {
    if(isScrolledIntoView($(".sec-nosotros-servicios"))) {
      $(".sec-nosotros-servicios .item").addClass("active");
    }
  }
}


if($("#sec-steps").length > 0) {
   setTimeout(function(){
     $("#sec-steps .item").addClass("active");
  }, 100);
}
