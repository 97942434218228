(function($) {

  function mnulateral() {
    $(".nav-link-parent").click(function(e) {
      e.preventDefault();
      $(this).parent("li").toggleClass("active");
    });
  }

  function validateOpcFilters() {

    var paramArr = location.pathname;
    var paramVal = paramArr.split("/");
    var searchSelect = [];
    var searchSelect2 = [];
    var err = 0;

    if (paramVal.length > 2) {

      paramVal.forEach(function(e) {
        if (e.includes(":")) {
          searchSelect.push(e);
        }
      });

      searchSelect.forEach(function(e) {
        $(".sf").each(function(index) {
          if (e.includes($(this).find("select").attr('data-select-id'))) {
            searchSelect2.push(e);
          }
        });
      });
    }

    return (searchSelect.length != searchSelect2.length) ? 1 : 0;

  }


  function mobilemenustore() {

    var totalErr = validateOpcFilters();

    // if (totalErr != 0) {
    //   var pathN = location.pathname.split("/");
    //   location.replace(location.origin + "/" + pathN[1] + "/" + pathN[2])
    // }

    $('.store-nav-mb').change(function() {
      var arrFilter = [];
      var idPag = ($(this).attr("data-type").length >0 && $(this).attr("data-type") == "catalogo")?"catalogo":"tienda";

      if($(".opc-modelo").length > 0) {

        if ($(".opc-modelo option:selected").attr('value').length != 0 && $(".opc-modelo option:selected").attr('value') !== null) {
          var urlpos1 = $(".opc-modelo option:selected").attr('value');
          arrFilter.push(urlpos1);
        }  

      }

      if($(".opcf-color").length > 0) {

        if ($(".opcf-color option:selected").attr('value').length != 0 && $(".opcf-color  option:selected").attr('value') !== null) {
          var urlpos2 = $(".opcf-color option:selected").attr('value');
          arrFilter.push(urlpos2);
        }

      }

      if($(".opcf-estilo").length > 0) {
        if ($(".opcf-estilo  option:selected").attr('value').length != 0 && $(".opcf-estilo option:selected").attr('value') !== null) {
          var urlpos3 = $(".opcf-estilo option:selected").attr('value');
          arrFilter.push(urlpos3);
        }
      }

     var urlF = arrFilter.join('/');
     var hasSlugPage = urlF.includes(idPag);
     var urlv2 = "";


       
      $(".store-left").attr("data-filter-opc", urlF);

      if(urlF == "") {        
         urlv2 = location.origin + "/" + idPag;       
      } else {

          if(hasSlugPage) {        
              urlv2 = location.origin + urlF;
          } else {
            urlv2 = location.origin + "/" + idPag + "/" + urlF;        
          }

      }

      
      //catalogo
      var res = urlv2.replace("//" + idPag, "/" +idPag); 
      console.log("test->" + res)
      location.replace(res);
   
      // if ($(".store-left").attr("data-filter-opc").length > 0) {
      //   location.replace(urlv2);
      // }

    });
  }

  function intercalarColores() {
    if(window.location.pathname == "/tienda") {
      $('.wrapper-items .item:odd').addClass('oddtshirt');
      $('.wrapper-items .item:even').addClass('eventshirt');
      // .eventshirt // oddtshirt
      $(".eventshirt").each(function() {
        $(this).find(".thumbcatalog").attr("src", $(this).find(".thumbcatalog").attr("data-change-color"));
        // cambiar URL a negro
        $(this).find(".link-item").attr("href", $(this).find(".link-item").attr("data-change-link"));
      });
    }//
  }

  mnulateral();
  mobilemenustore();
  intercalarColores();


}(jQuery));
