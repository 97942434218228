(function($) {
  var productAdd = {};



  function selectSize() {
    $(".selectsize label").click(function(e) {
      e.preventDefault();
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".selectsize .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.selectsize = valField;
    });
  }

  function selectClothescolor() {
    $(".clothescolor label").click(function(e) {
      e.preventDefault();


      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".clothescolor .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("label").attr("value");
      productAdd.clothescolor = valField;
      var imgThumb = (valField == "negro")?"data-img-thumb-black":"data-img-thumb-white";

      $(".imgproduct input").val($(".product-optional").attr(imgThumb));

      imgPrevChange(valField, $(".posplayera .group-field.active label").attr("value"));
    });
  }

  function selectCuello() {
    $(".selecttipocuello label").click(function(e) {
      e.preventDefault();
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".selecttipocuello .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.selecttipocuello = valField;
    });
  }

  function selectTextcolor() {
    $(".textcolor label").click(function(e) {
      e.preventDefault();

      $(".material-description .itemc").removeClass("initctxt");
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".textcolor .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("label").attr("value").split("-");
      productAdd.textcolor = "#" + valField[1];
      $("#box-design-edit").css("color", productAdd.textcolor);
      // añadir clase  para saber que  div  en tipo de material mostrar
      $(".material-description").removeClass("opc1");
      $(".material-description").removeClass("opc2");
      $(".material-description").removeClass("opc3");
      $(".material-description").removeClass("opc4");
      $(".material-description").removeClass("opc5");
      $(".material-description").removeClass("opc6");
      $(".material-description").addClass(valField[0]);
      //
    });
  }
  //

  function selectTypematerial() {

    $(".product-wrapper-content").addClass("reflejante-plata");
    $(".reflejante-plata .textcolor .group-field").eq( 4 ).find("label").trigger( "click" );


    $(".typematerial label").click(function(e) {
      e.preventDefault();

      $(".material-description").removeClass("opc1");
      $(".material-description").removeClass("opc2");
      $(".material-description").removeClass("opc3");
      $(".material-description").removeClass("opc4");
      $(".material-description").removeClass("opc5");
      $(".material-description").removeClass("opc6");

      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".typematerial .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.typematerial = valField;
        // añadir clase
      valField = valField.replace(" ", "-");
      valField = valField.toLowerCase();

      $(".product-wrapper-content").removeClass("reflejante-plata");
      $(".product-wrapper-content").removeClass("terciopelo");
      $(".product-wrapper-content").removeClass("vinil-mate");
      $(".product-wrapper-content").removeClass("vinil-brillante");
      $(".product-wrapper-content").addClass(valField);

      $(".textcolor .group-field:eq(0)").addClass("onfirstx");
      $(".reflejante-plata .textcolor .group-field").eq( 4 ).find("label").trigger( "click" );
      $(".terciopelo .textcolor .group-field").eq(0).find("label").trigger( "click" );
      $(".vinil-mate .textcolor .group-field").eq(0).find("label").trigger( "click" );
      $(".vinil-brillante .textcolor .group-field").eq(0).find("label").trigger( "click" );

    });
  }

  function selectFontType(){

    if($(".subtitle-design-2").length > 0) {
      $(".subtitle-design-2").attr("id", "defaultfont");
    }

    $('.fonttype select').change(function(){
        $(".subtitle-design-2").attr("id", $(this).find("option:selected").attr('value'));
        $("#box-design-edit").removeClass();
        $("#box-design-edit").addClass($(this).find("option:selected").attr('value'));
    });

  }


  function selectFontSize(){

    if($(".subtitle-design-3").length > 0) {
      // $(".subtitle-design-3").attr("id", "defaultfont");

      $(".subtitle-design-3").attr("id", $(".subtitle-design-3 option:selected").attr('value'));

    }

    $('.fontsize select').change(function(){
        $(".subtitle-design-3").attr("id", $(this).find("option:selected").attr('value'));
        var sizeDef = $(this).find("option:selected").attr('value');
         // sizeDef = sizeDef.replace("opc-", "");
         // productAdd.sizeText = sizeDef;

         $(".wrapper-product-bootom").removeClass("font-size-default");
         $(".wrapper-product-bootom").removeClass("font-size-chica");
         $(".wrapper-product-bootom").removeClass("font-size-mediana");
         $(".wrapper-product-bootom").removeClass("font-size-grande");
        $(".wrapper-product-bootom").addClass("font-size-" + sizeDef);
    });

  }

  function valuePrev(wordPrev) {
    var item = wordPrev.split(":");
    return item[1];
  }

  function selectPersona() {
      $(".selecttipersona .group-field label").click(function(e) {
        //e.preventDefault();
        if($(this).next("input").val() == "ninos"  || $(this).next("input").val() == "bebes" ) {
            $(".selecttipocuello .group-field").eq(1).hide();
        } else {
            $(".selecttipocuello .group-field").eq(1).show();
        }

      });
  }


if($(".pg-custom-design").length > 0) {
    selectSize();
    selectClothescolor();
    selectTextcolor();
    selectTypematerial();
    selectFontType();
    selectFontSize();
    selectPersona();
    selectCuello();
}


}(jQuery));
