(function($) {

  var itemsJson = {};

  //  ****************
  //  Incrementar cantidades de producto
  //  ****************

  $(".plus").click(function(e) {
    var btn = $(this);
    var inputInit = btn.prev().find("input");
    var rate = btn.prev().find("input").attr("data-rate");
    inputInit.val(parseInt(inputInit.val())+1);
    var total = parseFloat(inputInit.val()*rate);
    btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("data-subtotal", total);
    btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("data-qty", inputInit.val());
    var idItem = btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("id");
    inputInit.attr("value", inputInit.val());
    setNewTotal();
    updateQty(idItem, inputInit.val());
  });

  //  ****************
  //  Disminuir cantidades de producto
  //  ****************

  $(".minus").click(function(e) {
    var btn = $(this);
    var inputInit = btn.next().find("input");
    var rate = btn.next().find("input").attr("data-rate");

    if(inputInit.val() <= 1) {
      btn.prev().find("input").val(1);
    } else {
      qty = inputInit.val(parseInt(inputInit.val())-1);
    }
    var total = parseFloat(inputInit.val()*rate);
    btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("data-subtotal", total);
    var idItem = btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("id");
    btn.parent(".quantity").parent(".butttom-added").parent(".wrapper-right").parent(".item-add").attr("data-qty", inputInit.val());
    inputInit.attr("value", inputInit.val());
    setNewTotal();
    updateQty(idItem, inputInit.val());

  });

  //  ****************
  //  Colocar nuevos totales
  //  ****************

  function setNewTotal() {
    var total = 0;
    var costshipping = parseFloat($(".wrapper-buy").attr("data-costshipping"));

    $(".item-add").each(function() {
      total = total + parseFloat($(this).attr("data-subtotal"));
    });

    var totalF = total + costshipping;

    $(".wrapper-total-mb .total").text(total);
    $(".valtotal").text("$" + total);
    $(".wrapper-buy").attr("data-subtotal", total);
    $(".lb-subtotal input").val(total);
    $(".totalnumber").text("$" + totalF );
    $(".wrapper-buy").attr("data-total", totalF);

  }

  //  ****************
  //  Agregar cupones
  //  ****************

  function cuponAdd() {
    $(".wrapper-cartbuy .lb-addcuppon label").click(function(){
        $('.lbadded').remove();

        if($(".wrapper-cartbuy .lb-addcuppon input").val() != "") {
          $('.lb-addcuppon .form-content').append('<p class="lbadded">cupón añadido</p>');
        } else {
          $('.lb-addcuppon .form-content').append('<p class="lbadded">Ingrese un cupón</p>');
        }
    });
  }
  //  ****************
  //  valores iniciales
  //  ****************
  function initItems() {
    var item = 0;

    if(window.location.pathname == "/cart") {
        var totalQty = 0;
    } else {
        var totalQty = parseInt($(".total-cart").text());
    }


    var subtotal1 = parseFloat($(".wrapper-buy").attr("data-total"));
    var costshipping = parseFloat($(".wrapper-buy").attr("data-costshipping"));
    var totalF = subtotal1 + costshipping;

    $(".totalnumber").text("$" +  totalF);
    $(".valtotal").text("$" + $(".wrapper-buy").attr("data-total"));
    $(".wrapper-buy").attr("data-subtotal", subtotal1);
    $(".lb-subtotal input").val(subtotal1);


    $(".item-add").each(function() {
      itemsJson[item] = {
        "id": $(this).attr("id"),
        "qty": $(this).attr("data-qty")
      };

      item++;

      totalQty = totalQty + parseInt($(this).attr("data-qty"));
    });

    $(".cart-top .total-cart").text(totalQty);
    var updateItems = JSON.stringify(itemsJson);
    $(".jsonupdateitems input").val(updateItems);
  }

  //  ****************
  //  Actualizar cantidades
  //  ****************

  function updateQty(idItem, qtyVal) {
    var item = 0;
    var totalQty = 0;

    $.each(itemsJson, function(key, value) {
      if(value.id == idItem) {
        value.qty = qtyVal;
      }

      item++;
    });


    var updateItems = JSON.stringify(itemsJson);
    $(".jsonupdateitems input").val(updateItems);


    $(".item-add").each(function() {
      totalQty = totalQty + parseInt($(this).attr("data-qty"));
      $(".cart-top .total-cart").text(totalQty);
    });

  }
  function changeStyleFormBuy() {
    if($(".wrapper-buy form").length > 0) {
      $(".gralfield" ).each(function() {
        $($( this ).find(".form-content input")).before($( this ).find(".form-content label"));
      });
    }
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateFormToPaypal() {
          var err = 0;
          var valEmail = validateEmail($(".lb-email input").val());

          if(valEmail != true) {
            err++;
          }

          $(".gralfield-req" ).each(function() {
            if ($( this ).find("input").val() == "") {
              err++;
            }
          });

          if(err > 0) {
            $(".wrapper-paypal-button").addClass("badfields");
            $(".wrapper-paypal-button").removeClass("continuebuypal");
            //
            $(".wrapper-mercadopago").addClass("badfields");
            $(".wrapper-mercadopago").removeClass("continuemercadopago");

          } else {
            $(".wrapper-paypal-button").removeClass("badfields");
            $(".wrapper-paypal-button").addClass("continuebuypal");

            $(".wrapper-mercadopago").removeClass("badfields");
            $(".wrapper-mercadopago").addClass("continuemercadopago");
          }
  }


  $(".gralfield-req input").on('keyup', function(e) {
     validateFormToPaypal();
  });


  function mercadoPagoButton() {
    $("#btnpagarmercado").click(function(e) {
        e.preventDefault();
        $(".opcmercado input").val(1);
        $("#submit-buy").trigger("click");
    });
  }

  function textPromoCart() {
      if($(".pg-cart").length > 0) {
          $( ".pg-cart .lb-totalbuy" ).after( $( ".pg-cart .promos-text" ) );
      }
  }

  //
  function sessionSave(sec, idsec) {
    sessionStorage.removeItem(idsec);
    sessionStorage.setItem(idsec, sec);
  }

  //

  function saveCartEmail(){

      var cartObj = {};

      $( "#formbuy .form-field  input" ).focusout(function() {
          //console.log($(this).val());
          var arrItem1 = [];
          var classAll = $(this).parent("div").parent(".form-field").attr('class');
          var classWord = "";

        if (classAll.indexOf("username") > 0) {
            cartObj.username = $(this).val();
          }

        if (classAll.indexOf("email") > 0 ){
            cartObj.email = $(this).val();
        }

        if (classAll.indexOf('address') > 0) {
            cartObj.address = $(this).val();
        }

        if (classAll.indexOf('addcuppon') > 0) {
          cartObj.addcuppon = $(this).val();
        }


        var cartJson = JSON.stringify(cartObj);
        sessionSave(cartJson, "carts")

          //
      });


  }

 //
 function saveDataUser() {
     var secInfo = sessionStorage.getItem("carts");

   if (secInfo !== null) {
     var infoObj = JSON.parse(secInfo);

     if (infoObj.address) {
       $(".lb-address input").val(infoObj.address);
     }

     if (infoObj.email) {
       $(".lb-email input").val(infoObj.email);

     }

     if (infoObj.username) {
       $(".lb-username input").val(infoObj.username);
     }

     if (infoObj.addcuppon) {
       $(".lb-addcuppon input").val(infoObj.addcuppon);
     }

     validateFormToPaypal();

   }

 }



  cuponAdd();
  initItems();
  //buyStamp();
  changeStyleFormBuy();
  // validaFormBuy();
  mercadoPagoButton();
  textPromoCart();
  saveCartEmail();
  saveDataUser();

}(jQuery));
